import React from "react";
import { getImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";

import ButtonBase from "./ButtonBase";
import * as css from "../styles/CreationButton.module.scss";
import { classs } from "../utils/utils";
import I18n, { LocaleLink } from "../components/I18n";

const CreationButton = ({
  className,
  variant,
  slug,
  releaseDate,
  poster,
  image,
  title,
  directors,
  to,
  withReleaseYear = false,
  watermark,
  color,
  productor,
}) => (
  <ButtonBase
    component={LocaleLink}
    to={
      productor !== "other"
        ? to ?? `/${productor === "balthuslab" ? "artnum" : "film"}/${slug}`
        : null
    }
    className={classs(
      css.CreationButton,
      variant === "poster" ? variant : "standard",
      className,
      { inactive: (!to && !slug) || slug === "null" }
    )}
  >
    {withReleaseYear && (
      <span className="year">
        {!releaseDate || new Date(releaseDate) > new Date()
          ? "Prochainement"
          : new Date(releaseDate).getFullYear()}
      </span>
    )}
    <div
      style={{
        "--creation-color": (variant === "poster" ? poster : image)?.data
          ?.attributes?.fileS3
          ? "transparent"
          : color,
      }}
    >
      {watermark && (
        <div className="watermark">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <g fill="#fff">
              <path d="M42.598 8.866c-.701 2.908-2.628 4.205-5.606 4.205-4.8 0-5.291-4.485-5.291-8.129v-.841h11.108c0-1.557-.279-2.957-.89-4.101h-2.761c.299 1.184.357 2.59.357 3.961h-7.814c.013-1.527.093-2.872.365-3.961h-2.385c-.84 1.302-1.344 3.029-1.344 5.222 0 4.205 2.733 8.269 7.954 8.269 3.609 0 5.816-1.612 6.482-4.59l-.175-.035zm-29.31 4.786c5.812-.941 9.963-3.875 9.963-8.524 0-2.04-.575-3.748-1.589-5.128h-4.879c.638 1.499.934 3.293.934 5.183 0 4.871-.055 8.358-6.587 8.358h-5.038v.221h5.037c6.476 0 8.303 3.487 8.303 9.853 0 2.451-.36 4.641-1.207 6.384h4.497c1.415-1.651 2.246-3.772 2.246-6.384 0-6.088-4.484-8.911-11.68-9.963zm-8.801-9.576c1.495 0 2.768-1.162 2.768-2.767 0-.517-.111-.942-.266-1.309h-5.207c-.152.431-.229.871-.229 1.309 0 1.605 1.163 2.767 2.934 2.767zm-.553 21.92c-1.772 0-2.934 1.162-2.934 2.767 0 .413.069.829.207 1.237h5.257c.139-.35.237-.753.237-1.237 0-1.605-1.273-2.767-2.767-2.767zm27.175-6.366c0-1.612 1.051-2.593 3.048-2.593s3.784 1.226 4.52 3.644l.245.806h.175v-4.59h-.105c-.561.666-.981 1.121-1.401 1.121-.245 0-.526-.175-.736-.315-.876-.561-1.717-.806-2.768-.806-2.628 0-4.73 1.752-4.73 4.275 0 5.877 8.46 4.709 8.784 8.828h1.761c.07-.301.107-.616.107-.944-.035-6.238-8.9-4.906-8.9-9.426zm19.867-2.733c-5.676 0-8.234 3.924-8.234 8.339 0 1.697.434 3.371 1.295 4.764h2.723c-.564-1.514-.654-3.368-.654-5.044 0-3.889.456-7.919 4.87-7.919 2.278 0 2.733.631 2.733 1.191 0 .561-.806 1.156-.806 2.313 0 1.086.841 1.857 1.857 1.857 1.156 0 1.927-.771 1.927-1.857.001-1.717-1.961-3.644-5.711-3.644z"></path>
              <polygon points="66.989,15.986 64.431,9.433 61.733,9.433 66.849,15.986"></polygon>
              <path d="M73.541 24.115c0-4.17-1.997-7.218-7.043-7.218-3.924 0-7.428 2.558-7.428 8.339 0 1.697.446 3.371 1.32 4.764h2.729c-.58-1.514-.685-3.368-.685-5.044v-.841h11.107zm-7.043-7.078c3.399 0 3.749 3.574 3.749 6.938h-7.814c.036-4.135.562-6.938 4.065-6.938zm26.279 5.466c0-3.644-2.943-5.571-6.237-5.571-2.768 0-4.135 1.472-4.976 2.803v-2.418h-5.116v.105l.701.035c1.296.07 1.577.105 1.577 4.66v6.132c0 .68-.006 1.257-.021 1.75h2.951c-.015-.494-.021-1.071-.021-1.75v-8.339c.876-1.472 2.137-2.558 4.345-2.558 2.278 0 3.889 1.542 3.889 5.116v5.782c0 .68-.006 1.257-.021 1.75h2.951c-.015-.494-.021-1.071-.021-1.75v-5.747zm7.288 1.612h11.107c0-4.17-1.997-7.218-7.043-7.218-3.924 0-7.428 2.558-7.428 8.339 0 1.697.446 3.371 1.32 4.764h2.729c-.58-1.514-.685-3.368-.685-5.044v-.841zm4.064-7.078c3.399 0 3.749 3.574 3.749 6.938h-7.814c.035-4.135.561-6.938 4.065-6.938z"></path>
            </g>
          </svg>
        </div>
      )}
      <GatsbyImage
        image={getImage(
          (variant === "poster" ? poster : image)?.data?.attributes?.fileS3
        )}
        alt=""
      />
    </div>
    {variant === "poster" ? (
      (!releaseDate || new Date(releaseDate) > new Date()) && (
        <I18n
          component="div"
          className="not-released"
          id="film.soon_to_be_released"
        />
      )
    ) : (
      <div>
        <h3>{title}</h3>
        <p>
          {directors?.data?.map(({ attributes: { firstName, lastName } }) =>
            [firstName, lastName].filter((v) => v).join(" ")
          )}
        </p>
      </div>
    )}
  </ButtonBase>
);

export default CreationButton;
