import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import CreationButton from "../components/CreationButton";

import * as css from "../styles/Films.module.scss";
import { classs } from "../utils/utils";

import I18n from "../components/I18n";

const Films = ({
  pageContext: { locale, locales },
  location: { pathname },
  data: {
    strapi: {
      theme = {},
      films: { data: films = [] },
      productions: { data: productions = [] },
    } = {},
  },
}) => {
  const [tab, setTab] = useState(true);
  const action = (b) => () => setTab(b);
  return (
    <Layout
      pathname={pathname}
      theme={theme}
      className={classs(css.Films, { "in-production": !tab })}
      locale={locale}
      locales={locales}
    >
      <I18n id="title.films" component="h1" />
      <nav>
        <button className={classs({ active: tab })} onClick={action(true)}>
          <I18n id="title.films.status.ready" component="span" />
        </button>
        <button className={classs({ active: !tab })} onClick={action(false)}>
          <I18n id="title.films.status.production" component="span" />
        </button>
      </nav>
      {tab
        ? films
            .filter(({ attributes: { poster } }) => poster)
            .map(({ id, attributes }) => (
              <CreationButton variant="poster" key={id} {...attributes} />
            ))
        : productions
            .filter(({ attributes: { image } }) => image)
            .map(({ id, attributes }) => (
              <CreationButton key={id} {...attributes} />
            ))}
    </Layout>
  );
};

export const query = graphql`
  query ($locale: Strapi_I18NLocaleCode!) {
    strapi {
      films: creations(
        locale: $locale
        sort: "release_date:desc"
        filters: {
          productor: { eq: "pelleas" }
          status: { eq: "ready" }
          slug: { notNull: true }
        }
      ) {
        data {
          id
          attributes {
            slug
            releaseDate: release_date
            title
            color
            directors {
              data {
                id
                attributes {
                  firstName: first_name
                  lastName: last_name
                }
              }
            }
            poster {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 0.7261
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      productions: creations(
        locale: $locale
        sort: "release_date:desc"
        filters: {
          productor: { eq: "pelleas" }
          status: { eq: "production" }
          slug: { notNull: true }
        }
      ) {
        data {
          id
          attributes {
            slug
            releaseDate: release_date
            title
            color
            directors {
              data {
                id
                attributes {
                  firstName: first_name
                  lastName: last_name
                }
              }
            }
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.5625
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Films;
